import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  const maxPageNumbersToShow = 5;

  // Add "Prev" button
  if (currentPage > 1) {
    pageNumbers.push(
      <Link  className="prev page-numbers" key="prev" to={`/news/page/${currentPage - 1}.html`} onClick={() => onPageChange(currentPage - 1)}>
        Previous
      </Link>
    );
  }
  // Add first page and "..." if needed
  if (currentPage > 3) {
    pageNumbers.push(
      <Link className='page-numbers' key={1} to={`/news/page/1.html`} onClick={() => onPageChange(1)}>
        1
      </Link>
    );
    if (currentPage > 4) {
      pageNumbers.push(<span key="dots1">...</span>);
    }
  }

  // Add pages around the current page
  for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
    pageNumbers.push(
      <Link   className={`page-numbers }`}
        key={i}
        to={`/news/page/${i}.html`}
        onClick={() => onPageChange(i)}
        style={{ fontWeight: i === currentPage ? 'bold' : 'normal' }}
      >
        {i}
      </Link>
    );
  }

  // Add "..." and last page if needed
  if (currentPage < totalPages - 2) {
    if (currentPage < totalPages - 3) {
      pageNumbers.push(<span key="dots2">...</span>);
    }
    pageNumbers.push(
      <Link  className=" page-numbers" key={totalPages} to={`/news/page/${totalPages}.html`} onClick={() => onPageChange(totalPages)}>
        {totalPages}
      </Link>
    );
  }

  // Add "Next" button
  if (currentPage < totalPages) {
    pageNumbers.push(
      <Link   className="next page-numbers" key="next" to={`/news/page/${currentPage + 1}.html`} onClick={() => onPageChange(currentPage + 1)}>
        Next
      </Link>
    );
  }

  return <div className="nav-links">   {pageNumbers}</div>;
};

export default Pagination;
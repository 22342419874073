import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import RightContent from './RightContent';
const maxLength = 100; // Define the maximum length for truncation

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

const NewsCategory = () => {
  const [categories, setCategories] = useState(null);
  const [rightContentNews, setRightContentNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, rightContentNewsResponse] = await Promise.all([
          axios.get('https://api.casansaar.co/api/news-all-cat'),
          axios.get('https://api.casansaar.co/api/right-content-news')
        ]);

        setCategories(categoriesResponse.data.data);
        setRightContentNews(rightContentNewsResponse.data.data);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <main className="articles_page">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="articles-list">
                <div className="main_heading">
                  News : <span className="co-y font12">All Categories</span>
                </div>
                <div className="cont_bord pad_b20">
                  <ul className="newlist bo_none">
                    {categories && categories.map((category) => (
                      <li className="link-arrow1" key={category.category_id}>
                        <Link to={`/${category.full_url}`}> 
                          {category.category_name} ({category.news_count})
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>            
            <RightContent></RightContent>
          </div>
        </div>
      </section>
    </main>
  );
};

export default NewsCategory;

import React , {useState} from "react";

const NoPage = () => {
   

    return (
     <div>Page not found</div>

    )
}
export default NoPage;
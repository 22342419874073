import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { img1, loader, g_add, printImg } from '../../assets/assest';

const RightContent = ({ onSearch, searchQuery }) => {
  const [NewsCategory, setNewsCategory] = useState([]);
  const [RecentNews, setRecentNews] = useState([]);
  const [PopularNews, setPopularNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.casansaar.co/api/right-content-news');
        if (response.data.success === 1) {
          setNewsCategory(response.data.data.category.data);

        //  console.log('NewsCategory'+ response.data.data.category.data);
          setRecentNews(response.data.data.recent.data);
          setPopularNews(response.data.data.popular.data);
        } else {
          setError('Failed to fetch data');
        }
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    onSearch(e.target.value); // Trigger the search function passed from NewsBrowse.js
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div className="col-md-4 m_toppadd fil_right">
        <div className="rbox cont_bord">
          <div className="main_heading">
            Search News
          </div>
          <div className="rbox-cont">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Search"
                value={searchQuery} // Controlled input
                onChange={handleSearchChange} // Update search query
              />
              <span className="input-group-btn">
                <button className="btn btn-blue search" type="button" onClick={() => onSearch(searchQuery)}>
                  Search
                </button>
              </span>
 
            </div>
          </div>
        </div>
        <div className="rbox cont_bord">
          <div className="main_heading">
            News By Categories <span className="h-link pull-right"><Link to="/news-category.html">More Categories</Link></span>
          </div>
          <div className="rbox-cont">
            <ul className="ca_news">
              {NewsCategory.map((category) => (              
                 <li className="link-arrow1" key={category.category_id}>
                  <Link to={`/${category.full_url}`}> {category.category_name} ({category.news_count})</Link></li>
              ))}
            </ul>
          </div>
        </div>
   
        <div>
 
 <ul className="nav nav-tabs" role="tablist">
     <li className="nav-item" role="presentation">
         <a className="nav-link active" id="popular-tab" data-bs-toggle="tab" href="#popular" role="tab"
             aria-controls="popular" aria-selected="true">Popular</a>
     </li>
     <li className="nav-item" role="presentation">
         <a className="nav-link" id="latest-tab" data-bs-toggle="tab" href="#latest" role="tab" aria-controls="latest"
             aria-selected="false">Latest</a>
     </li>
    <li className="nav-item" role="presentation" style={{display: 'none'}}>
         <a className="nav-link" id="comments-tab" data-bs-toggle="tab" href="#comments" role="tab"
             aria-controls="comments" aria-selected="false">Comments</a>
     </li>
 </ul>


 <div className="tab-content">
     <div className="tab-pane  show active" id="popular" role="tabpanel" aria-labelledby="popular-tab">
         <ul className="tabdiscussion newlist bo_none">
         {PopularNews.map((popularNewsdata) => (              
                 <li className="link-arrow1" key={popularNewsdata.news_id}>
                  <Link to={`/${popularNewsdata.full_url}`}> {popularNewsdata.news_title} </Link></li>
              ))}

         </ul>
     </div>
     <div className="tab-pane " id="latest" role="tabpanel" aria-labelledby="latest-tab">
         <ul className="tabdiscussion newlist bo_none">
         {RecentNews.map((reactecentNewsdata) => (              
                 <li className="link-arrow1" key={reactecentNewsdata.news_id}>
                  <Link to={`/${reactecentNewsdata.full_url}`}> {reactecentNewsdata.news_title} </Link></li>
              ))}

         </ul>
     </div>
     <div className="tab-pane " id="comments" role="tabpanel" aria-labelledby="comments-tab" style={{display: 'none'}}>
         <ul className="tabdiscussion newlist bo_none">
             <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
             <li className="link-arrow1"><a href=""> Accounting of Joint Development Agreement</a></li>
             <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY DIWALI</a></li>
             <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY DUSSEHRA</a></li>
             <li className="link-arrow1"><a href=""> Return of Income</a></li>
             <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY HOLI, HEALTHY HOLI.</a></li>
             <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST Portal</a></li>
             <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not taking Registration in
                     other state</a></li>
             <li>
                 <div className="more_link"><a href="">View More..</a></div>
             </li>
         </ul>
     </div>
 </div>
</div>
 



        <div className="rbox cont_bord">




        </div>

        
        <div className="rbox rel text-center gadds">
          <img src="assets/images/300X600_1.jpg" alt="" className="img-responsive" />
        </div>
      </div>
    </>
  );
};

export default RightContent;

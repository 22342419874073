import React , {useState} from "react";
import { Link } from "react-router-dom";

import calogo from '../../assets/images/calogo.jpg';
import headerAds from '../../assets/images/header_ads.jpg';
import headerAds2 from '../../assets/images/header_ads2.jpg';
import usericon from '../../assets/images/icons/user_icon.png';
import g_add from '../../assets/images/g_add.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/style.css';
import '../../assets/css/custom.css';


const Headers = () => {

    const [isLeftOpen, setIsLeftOpen] = useState(false);
    const [isOpen, setIsOpen] = useState({
      topOpen: false,
      bottomOpen: false,
      rightOpen: false,
      leftOpen: false,
      pushleftOpen: false,
      pushrightOpen: false
    });

    const handleShowLeftClick = () => {
        setIsLeftOpen(!isLeftOpen);
      };
    
      const handleBackBtnClick = () => {
        setIsLeftOpen(false);
       
      };
    
  

    return (
        <>        
         <header id="header" className="hidden-sm hidden-xs">
         <div className="top_head">
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <div className="web_add"><Link to="">casansaar.com</Link>-Initiative of VIVEK KHURANA</div>
                </div>
                <div className="col-6 text-end">
                    <div className="float-end userpanel">
                        <b className="username">Vipin Kumar </b> | <Link to="#" className="dropdown-toggle"
                            data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">My Account
                            </Link>
                        <ul className="dropdown-menu">
                            <li><Link to="#"><span className="fa fa-user"></span> Friend Request</Link></li>
                            <li><Link to="#"><span className="fa fa-envelope"></span> Messages <span
                                        className="badge">42</span></Link></li>
                            <li><Link to="#"><span className="fa fa-power-off"></span> Logout</Link></li>
                        </ul>
                    </div>
                    <div className="float-end h_search">
                        <form action="https://www.casansaar.com/search.php" id="cse-search-box">
                            <div>
                                <input type="hidden" name="cx" value="007874090545644231611:ukbdzlrgu0a" />
                                <input type="hidden" name="cof" value="FORID:10" />
                                <input type="hidden" name="ie" value="UTF-8" />
                                <input type="text" name="q" style={{width: "144px"}} />
                                <input type="submit" name="sa" value="Search" />
                            </div>
                        </form>
                        <script type="text/javascript"
                            src="https://www.google.com/coop/cse/brand?form=cse-search-box&amp;lang=en">
                        </script>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container main-header">
        <div className="row">
            <div className="col-2"><img src={calogo} alt="Logo" className="hlogo" /></div>
            <div className="col-10 text-end right_imgs">
                <img src={headerAds} alt="" className="adds1" />
                <img src={headerAds2}alt="" className="adds2" />
            </div>
        </div>
    </div>

    <nav className="navbar navbar-expand-lg navbar-inverse navbar-static-top">
        <div className="container">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
                aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navbar" className="collapse navbar-collapse">
                <ul className="navbar-nav nav  me-auto mb-2 mb-lg-0">
                    <li className="nav-item"><Link className="nav-link" to="index.html">Home</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="/news.html">News</Link></li>
                    <li className="nav-item active"><Link className="nav-link" to="articles.html">Articles</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Events</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Jobs</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Article Trainees</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Website</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="empanelment.html">Empanelment</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Files</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Matrimonial</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Queries</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="#">Classified Ads</Link></li>
                    <li className="nav-item dropdown dropdown-static"> 
                        <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" >More </Link>
 <div id="megamenu" className="dropdown-menu mega-menu">
                            <div className="container">
                                <div className="row">
                                    <ul className="col">
                                        <li><Link className="dropdown-item" to="#">CA Community</Link></li>
                                        <li><Link className="dropdown-item" to="#">CA Firms</Link></li>
                                        <li><Link className="dropdown-item" to="#">Top Members</Link></li>
                                        <li><Link className="dropdown-item" to="#">Coaching Centres</Link></li>
                                    </ul>
                                    <ul className="col">
                                        <li><Link className="dropdown-item" to="#">Testimonials</Link></li>
                                        <li><Link className="dropdown-item" to="#">Videos</Link></li>
                                        <li><Link className="dropdown-item" to="#">Notifications/Circulars</Link></li>
                                        <li><Link className="dropdown-item" to="#">Book</Link></li>
                                    </ul>
                                    <ul className="col">
                                        <li><Link className="dropdown-item" to="#">eDirectory</Link></li>
                                        <li><Link className="dropdown-item" to="#">Score Board</Link></li>
                                        <li><Link className="dropdown-item" to="#">Press Release</Link></li>
                                        <li><Link className="dropdown-item" to="#">Members</Link></li>
                                    </ul>
                                    <ul className="col">
                                        <li><Link className="dropdown-item" to="#">Advertise us</Link></li>
                                        <li><Link className="dropdown-item" to="#">Contact us</Link></li>
                                        <li><Link className="dropdown-item" to="#">Interviews</Link></li>
                                        <li><Link className="dropdown-item" to="#">Students Zone</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </li>
                </ul>
                
            </div>
        </div>
    </nav>
</header>

  <header id="mobile_header" className="d-md-none">
   <Link to="#" id="showLeft" className="mo_menu"  onClick={handleShowLeftClick}><span className="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span></Link>
    <div className="m_logo">
        <img src={calogo} alt="Logo" className="img-fluid" />
    </div>
    <div className="header_right">
        <div className="search_dev">
            <button type="button" className="searcicon btn btn-link" data-bs-toggle="modal" data-bs-target="#mysearch">
                <span className="bi bi-search" aria-hidden="true"></span>
            </button>
        </div>
        <div className="usernot">
            <span className="bi bi-bell" aria-hidden="true"></span>
        </div>
        <div className="useradmin dropdown">
            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                <img src={usericon} alt="" className="rounded-circle" />
            </Link>
            <ul className="dropdown-menu">
                <li><Link className="dropdown-item disabled" to="#" tabIndex="-1" aria-disabled="true"><span className="bi bi-person"></span> Vipin Shrivastava</Link></li>
                <li><hr className="dropdown-divider"></hr></li>
                <li><Link className="dropdown-item" to="#"><span className="bi bi-heart"></span> Friend Request</Link></li>
                <li><Link className="dropdown-item" to="#"><span className="bi bi-envelope"></span> Messages <span className="badge bg-secondary">42</span></Link></li>
                <li><Link className="dropdown-item" to="#"><span className="bi bi-power"></span> Logout</Link></li>
            </ul>
        </div>
    </div>
    <nav className={`menu menu-left ${isLeftOpen ? 'left-open' : ' '}`}>
        <Link to="#" className="backBtn" onClick={handleBackBtnClick}>CA Sansaar <span>&times;</span></Link>
        <Link to="index.html">Home</Link>
        <Link to="news.html" className="active">News</Link>
        <Link to="articles.html">Articles</Link>
        <Link to="">Events</Link>
        <Link to="">Jobs</Link>
        <Link to="">Article Trainees</Link>
        <Link to="">Website</Link>
        <Link to="empanelment.html">Empanelment</Link>
        <Link to="">Files</Link>
        <Link to="">Matrimonial</Link>
        <Link to="">Queries</Link>
        <Link to="">Classified Ads</Link>
        <Link to="">CA Community</Link>
        <Link to="">CA Firms</Link>
        <Link to="">Top Members</Link>
        <Link to="">Coaching Centres</Link>
        <Link to="">Testimonials</Link>
        <Link to="">Videos</Link>
        <Link to="">Notifications/Circulars</Link>
        <Link to="">Book</Link>
        <Link to="">eDirectory</Link>
        <Link to="">Score Board</Link>
        <Link to="">Press Release</Link>
        <Link to="">Member</Link>
        <Link to="">Advertise us</Link>
        <Link to="">Contact us</Link>
        <Link to="">Interview</Link>
        <Link to="">Students zone</Link>
    </nav>
</header>

<section className="g_adds">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 text-center">
                    <img src={g_add} alt="" className="img-responsive" />
                </div>
            </div>
        </div>

    </section>
        
        </>
    )
}
export default Headers;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import RightContent from './RightContent';

import { img1, printImg } from '../../assets/assest.js'; // Importing the images from assets.js

function NewsDetails() {
  const { cat, title, id } = useParams();
  const [responseData, setResponse] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const response = await axios.get(`https://api.casansaar.co/api/news/${id}`);

        console.log((response.data.data));
        setResponse(response.data.data);
      } catch (err) {
        setError('Error fetching news details');
      } finally {
        setLoading(false);
      }
    };

    fetchNewsDetail();
  }, [id]);


  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
   <>
    <main className="articles_page">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="detailpage">
                  <div className="main_heading">
                    News Details --- <span>(Get Professional Updates on Whatsapp, Msg on 8285393786)</span>
                    <span className="pull-right">
                      <Link to="/news.html"> more news </Link>
                    </span>
                  </div>

                  <div className="ullist dish cont_bord">
                    <div className="list-cont">
                      <div className="newsheading">
                        <h4>{responseData[0].news_title}</h4>
                      </div>
                      <div className="post_dett">
                        <div className="post_cont">
                          <span>
                            Posted Date: {responseData[0].posted_date} | Posted By
                            <Link to=""> {responseData[0].posted_by} </Link>
                          </span>
                          <span className="print">
                            <Link to="">
                              <img src={printImg} alt="" width="20" />
                              &nbsp;Print
                            </Link>
                          </span>
                        </div>
                      </div>
                      &nbsp;
                      <div className="postdata">
                        <div dangerouslySetInnerHTML={{ __html: responseData[0].news_matter }} />
                      </div>
                      &nbsp;
                      <div>
                        Source: <Link to={responseData[0].source_url}>Click Here</Link>
                      </div>
                      <p className="categoryshort">
                        Category: <Link to={cat}>{cat}</Link> | Comments: 0 | Hits: {responseData[0].hits}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <RightContent></RightContent>

            </div>
          </div>
        </section>
      </main>
   
   
   
   </>
  );
}

export default NewsDetails;

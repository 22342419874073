import React from 'react';
import web_adds from '../assets/images/web_adds01.jpg';
import g_add from '../assets/images/g_add.jpg';
import g_add_m from '../assets/images/g_add_m.jpg';
import sensexImg from '../assets/images/sensex_img.jpg';
import home from '../assets/images/icons/001-house.png';
import news_mobile_icon from '../assets/images/icons/002-newspaper.png';
import emailImg from '../assets/images/icons/email-icon.png';
     
import article_img from '../assets/images/icons/003-copyright.png'  ;                      
import job_img from '../assets/images/icons/005-businessman.png';
import trainee from '../assets/images/icons/020-instructor.png';
import empanelment from '../assets/images/icons/007-target.png';
import devWebsite from '../assets/images/icons/009-xing-website-logo.png';
import notification from '../assets/images/icons/025-bell.png';
import queries from '../assets/images/icons/014-query-analysis.png';
import event from '../assets/images/icons/004-event.png';
import videoImg from '../assets/images/icons/024-video-camera.png';
import classified from '../assets/images/icons/015-classified.png';

import filesImg from '../assets/images/icons/011-file.png';                        
import Matrimonial from '../assets/images/icons/013-wedding-couple.png';


const Home = () => {
  return (
   <>
  <main className="hidden-md hidden-lg">
        <section className="m_hocont">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={home} alt="" className="iconimg" />
                                <div className="link_name">Home</div>
                            </a>
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={emailImg} alt="" className="iconimg"/>
                                <div className="link_name">Subscribe for Email</div>
                            </a>
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={news_mobile_icon} alt="" className="iconimg" />
                                <div className="link_name">News</div>
                            </a>
                        </div>

                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={article_img} alt="" className="iconimg" />
                                <div className="link_name">Articles</div>
                            </a>
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={job_img} alt="" className="iconimg" />
                                <div className="link_name">Jobs</div>
                            </a>
                        </div>
                        
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={trainee} alt="" className="iconimg" />
                                <div className="link_name">Trainees</div>
                            </a>
                        </div>
                        <div className="add_secimg">
                            <img src={g_add_m} className="img-responsive" />
                        </div>
                                           
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={empanelment} alt="" className="iconimg" />
                                <div className="link_name">Empanelment</div>
                            </a>
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={devWebsite}alt="" className="iconimg" />
                                <div className="link_name">Develop Website</div>
                            </a>
                        </div>

                        
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={notification} alt="" className="iconimg" />
                                <div className="link_name">Notifications</div>
                            </a>
                        </div>

                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={queries} alt="" className="iconimg" />
                                <div className="link_name">Queries</div>
                            </a>
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={event} alt="" className="iconimg" />
                                <div className="link_name">Events</div>
                            </a>
                        </div>


                   

                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={videoImg} alt="" className="iconimg" />
                                <div className="link_name">Videos</div>
                            </a>
                        </div>
                        <div className="add_secimg">
                            <img src={g_add_m} className="img-responsive" />
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={classified} alt="" className="iconimg" />
                                <div className="link_name">classified Ads</div>
                            </a>
                        </div>

                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={filesImg} alt="" className="iconimg" />
                                <div className="link_name">Files</div>
                            </a>
                        </div>

                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src={Matrimonial} alt="" className="iconimg" />
                                <div className="link_name">Matrimonial</div>
                            </a>
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src="assets/images/icons/blood-bank.png" alt="" className="iconimg" />
                                <div className="link_name">Blood Bank</div>
                            </a>
                        </div>
                        <div className="icon_link">
                            <a href="" className="mlink">
                                <img src="assets/images/icons/033-time-zone.png" alt="" className="iconimg" />
                                <div className="link_name">Students Zone</div>
                            </a>
                        </div>

                        <div className="icon_link" id="morem">
                            <div className="mlink">
                                <img src="assets/images/icons/more.png" alt="" className="iconimg" />
                                <div className="link_name">More +</div>
                            </div>
                        </div>

                        <div className="morlink more_mcont">
                            <div className="add_secimg">
                                <img src={g_add_m} className="img-responsive" />
                            </div>

                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/019-membership.png" alt="" className="iconimg" />
                                    <div className="link_name">Members</div>
                                </a>
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/021-coach.png" alt="" className="iconimg" />
                                    <div className="link_name">Coaching</div>
                                </a>
                            </div>


                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/018-seo.png" alt="" className="iconimg" />
                                    <div className="link_name">CA Firms</div>
                                </a>
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/026-book.png" alt="" className="iconimg" />
                                    <div className="link_name">Books</div>
                                </a>
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/023-testimonial.png" alt="" className="iconimg" />
                                    <div className="link_name">Testimonials</div>
                                </a>
                            </div>

                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/030-megaphone.png" alt="" className="iconimg" />
                                    <div className="link_name">Advertise us</div>
                                </a>
                            </div>



                            <div className="add_secimg">
                                <img src={g_add_m} className="img-responsive" />
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/027-folder.png" alt="" className="iconimg" />
                                    <div className="link_name">eDirectory</div>
                                </a>
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/028-score.png" alt="" className="iconimg" />
                                    <div className="link_name">Score Board</div>
                                </a>
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/032-meeting.png" alt="" className="iconimg" />
                                    <div className="link_name">Interviews</div>
                                </a>
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/029-press-release.png" alt="" className="iconimg" />
                                    <div className="link_name">Press Release</div>
                                </a>
                            </div>




                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/022-mentor.png" alt="" className="iconimg" />
                                    <div className="link_name">CA Community</div>
                                </a>
                            </div>
                            <div className="icon_link">
                                <a href="" className="mlink">
                                    <img src="assets/images/icons/031-call-center-agent.png" alt="" className="iconimg" />
                                    <div className="link_name">Contact us</div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="modal fade login_popup" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">

                    </div>
                </div>
            </div>
        </div>
    </main>
    
    <main id="mainsecion" className="hidden-xs hidden-sm">
        <section className="wb_add padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 hidden-sm hidden-xs">
                        <div className="subscribe_sec">
                            <h3><span>Get Daily Updates</span> Via Email</h3>

                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Enter email id" />
                                <span className="input-group-btn">
                                    <button className="btn btn-blue" type="button">Subscribe</button>
                                </span>
                            </div>
                        </div>
                        <div className="webadds_img text-center">
                            <img src={web_adds} alt="" className="img-responsive" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                           
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#latest" aria-controls="latest"
                                        role="tab" data-toggle="tab">Latest News</a></li>
                                <li role="presentation"><a href="#popular" aria-controls="popular" role="tab"
                                        data-toggle="tab">Popular News</a></li>
                                <li role="presentation"><a href="#gst" aria-controls="gst" role="tab"
                                        data-toggle="tab">GST News</a></li>
                                <li role="presentation"><a href="#discussion" aria-controls="discussion" role="tab"
                                        data-toggle="tab">Discussion</a></li>
                                <li role="presentation"><a href="#videos" aria-controls="videos" role="tab"
                                        data-toggle="tab">Videos</a></li>
                            </ul>

                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane fade in active" id="latest">
                                    <ul className="tablatest newlist">
                                        <li>
                                            <div className="tab_des"><a href="">Video recording of the Webinar on Bank
                                                    Branch Statutory Audit</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">YES Bank gets Rs 60k Cr credit line from
                                                    RBI to resume operations</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">PM Modi to address nation tonight on
                                                    Coronavirus</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Webinar on Bank Branch Statutory Audit
                                                    also on YouTube</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Govt set to overhaul Audit regime to
                                                    check lapses in governance</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Bank branches could be shut for 4 days
                                                    next week due to strike, holidays</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="more_link"><a href="">View More..</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="popular">
                                    <ul className="tabpopular newlist">
                                        <li>
                                            <div className="tab_des"><a href="">Video recording of the Webinar on Bank
                                                    Branch Statutory Audit</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">YES Bank gets Rs 60k Cr credit line from
                                                    RBI to resume operations</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">PM Modi to address nation tonight on
                                                    Coronavirus</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Webinar on Bank Branch Statutory Audit
                                                    also on YouTube</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Govt set to overhaul Audit regime to
                                                    check lapses in governance</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Bank branches could be shut for 4 days
                                                    next week due to strike, holidays</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="more_link"><a href="">View More..</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="gst">
                                    <ul className="tabgst newlist">
                                        <li>
                                            <div className="tab_des"><a href="">Video recording of the Webinar on Bank
                                                    Branch Statutory Audit</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">YES Bank gets Rs 60k Cr credit line from
                                                    RBI to resume operations</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">PM Modi to address nation tonight on
                                                    Coronavirus</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Webinar on Bank Branch Statutory Audit
                                                    also on YouTube</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Govt set to overhaul Audit regime to
                                                    check lapses in governance</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Bank branches could be shut for 4 days
                                                    next week due to strike, holidays</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="more_link"><a href="">View More..</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="discussion">
                                    <ul className="tabdiscussion newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">View More..</a></div>
                                        </li>
                                    </ul>
                                </div>

                                <div role="tabpanel" className="tab-pane fade" id="videos">
                                    <ul className="tabvideos newlist">
                                        <li>
                                            <div className="tab_des"><a href="">Video recording of the Webinar on Bank
                                                    Branch Statutory Audit</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">YES Bank gets Rs 60k Cr credit line from
                                                    RBI to resume operations</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">PM Modi to address nation tonight on
                                                    Coronavirus</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Webinar on Bank Branch Statutory Audit
                                                    also on YouTube</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Govt set to overhaul Audit regime to
                                                    check lapses in governance</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="tab_des"><a href="">Bank branches could be shut for 4 days
                                                    next week due to strike, holidays</a></div>
                                            <div className="sub_des">19-Mar-2020, 12:06:27 pm</div>
                                        </li>
                                        <li>
                                            <div className="more_link"><a href="">View More..</a></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 hidden-sm hidden-xs">
                        <div className="subscribe_sec">
                            <h3><span>Get Daily Updates</span> Via Email</h3>

                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Enter email id" />
                                <span className="input-group-btn">
                                    <button className="btn btn-blue" type="button">Subscribe</button>
                                </span>
                            </div>
                        </div>
                        <div className="webadds_img text-center">
                            <img src={web_adds} alt="" className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="g_adds">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <img src={g_add} alt="" className="img-responsive" />
                    </div>
                </div>
            </div>

        </section>
        <section className="la_new padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#lanew_articles"
                                        aria-controls="lanew_articles" role="tab" data-toggle="tab">Articles</a>
                                </li>
                                <li role="presentation"><a href="#lanew_files" aria-controls="lanew_files" role="tab"
                                        data-toggle="tab">Files</a></li>
                                <li role="presentation"><a href="#lanew_discussion" aria-controls="lanew_discussion"
                                        role="tab" data-toggle="tab">Discussion</a></li>
                                <li role="presentation"><a href="#lanew_queries" aria-controls="lanew_queries"
                                        role="tab" data-toggle="tab">Queries</a></li>
                                <li role="presentation"><a href="#lanew_circulars" aria-controls="lanew_circulars"
                                        role="tab" data-toggle="tab">Circulars</a></li>
                                <li role="presentation"><a href="#lanew_firms" aria-controls="lanew_firms" role="tab"
                                        data-toggle="tab">CA Firms</a></li>
                            </ul>


                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane fade tab_minhe in active" id="lanew_articles">
                                    <ul className="tablatest newlist bo_none">
                                        <li className="link-arrow1"><a href=""> LIST OF IMPORTANT CIRCULARS FOR BRANCH
                                                STATUTORY AUDIT</a></li>
                                        <li className="link-arrow1"><a href=""> Compliances for Newly Incorporated
                                                Private Company</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Non Residents taxation on shipping
                                                business read with section 172 of the income tax act, 1961</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>

                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Submit</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_files">
                                    <ul className="tabpopular newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Submit</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_discussion">
                                    <ul className="tabgst newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Submit</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_queries">
                                    <ul className="tabdiscussion newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Submit</a></div>
                                        </li>
                                    </ul>
                                </div>

                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_circulars">
                                    <ul className="tabvideos newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Submit</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_firms">
                                    <ul className="tabvideos newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Submit</a></div>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>


                    <div className="col-md-6">
                        <div>
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#lanew_assistants"
                                        aria-controls="lanew_assistants" role="tab"
                                        data-toggle="tab">Articles/Assistants</a></li>
                                <li role="presentation"><a href="#lanew_jobs" aria-controls="lanew_jobs" role="tab"
                                        data-toggle="tab">Jobs</a></li>
                                <li role="presentation"><a href="#lanew_matrimonials" aria-controls="lanew_matrimonials"
                                        role="tab" data-toggle="tab">Matrimonials</a></li>
                                <li role="presentation"><a href="#lanew_empanelments" aria-controls="lanew_empanelments"
                                        role="tab" data-toggle="tab">Empanelments</a></li>
                                <li role="presentation"><a href="#lanew_events" aria-controls="lanew_events" role="tab"
                                        data-toggle="tab">Events</a></li>
                            </ul>
                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane fade tab_minhe in active" id="lanew_assistants">
                                    <ul className="tablatest newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Post
                                                    Requirement</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_jobs">
                                    <ul className="tabpopular newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Post
                                                    Requirement</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_matrimonials">
                                    <ul className="tabgst newlist bo_none">
                                        <li>
                                            <div className="tab_des">
                                                <div className="met_img"><a href=""><img height="60" width="60"
                                                            src="assets/images/1543038832Photo -VIJAY SIR.jpg"
                                                            alt="Sagar Aggarwal" /></a></div>

                                                <div className="met_cont">
                                                    <div className="mat_name"><a
                                                            href=""><strong>AJZS5553(25-30)</strong></a></div>
                                                    <p className="date_text">5'10" inch |
                                                        Male | India | Rajasthan | Jaipur | CA Job |&nbsp;Hindu </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="tab_des">
                                                <div className="met_img"><a href=""><img height="60" width="60"
                                                            src="assets/images/1543038832Photo -VIJAY SIR.jpg"
                                                            alt="Sagar Aggarwal" /></a></div>

                                                <div className="met_cont">
                                                    <div className="mat_name"><a
                                                            href=""><strong>AJZS5553(25-30)</strong></a></div>
                                                    <p className="date_text">5'10" inch |
                                                        Male | India | Rajasthan | Jaipur | CA Job |&nbsp;Hindu </p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div className="tab_des">
                                                <div className="met_img"><a href=""><img height="60" width="60"
                                                            src="assets/images/1543038832Photo -VIJAY SIR.jpg"
                                                            alt="Sagar Aggarwal" /></a></div>

                                                <div className="met_cont">
                                                    <div className="mat_name"><a
                                                            href=""><strong>AJZS5553(25-30)</strong></a></div>
                                                    <p className="date_text">5'10" inch |
                                                        Male | India | Rajasthan | Jaipur | CA Job |&nbsp;Hindu </p>
                                                </div>
                                            </div>

                                        </li>

                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Post
                                                    Requirement</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_empanelments">
                                    <ul className="tabdiscussion newlist bo_none">
                                        <li className="link-arrow1"><a href="">Appointment of Concurrent Auditor for
                                                Maharashtra State Co-operative Bank L....</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Post
                                                    Requirement</a></div>
                                        </li>
                                    </ul>
                                </div>

                                <div role="tabpanel" className="tab-pane fade tab_minhe" id="lanew_events">
                                    <ul className="tabvideos newlist bo_none">
                                        <li className="link-arrow1"><a href=""> Happy New Year 2020</a></li>
                                        <li className="link-arrow1"><a href=""> Accounting of Joint Development
                                                Agreement</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DIWALI</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you and your family a very HAPPY
                                                DUSSEHRA</a></li>
                                        <li className="link-arrow1"><a href=""> Return of Income</a></li>
                                        <li className="link-arrow1"><a href=""> Wishing you &amp; Your family HAPPY
                                                HOLI, HEALTHY HOLI.</a></li>
                                        <li className="link-arrow1"><a href=""> Availability of GSTR - 9 on GST
                                                Portal</a></li>
                                        <li className="link-arrow1"><a href=""> GST Charging by GST in one state and not
                                                taking Registration in other state</a></li>
                                        <li>
                                            <div className="more_link"><a href="">More</a> | <a href="">Post
                                                    Requirement</a></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="sensex_iframe padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <img src={sensexImg} alt="" className="img-responsive" />
                    </div>
                </div>
            </div>
        </section>



        <section className="socile_web padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="y_video">
                            <iframe width="900" height="506" className="iframe_video"
                                src="https://www.youtube.com/embed/B_CBPgb-0-Y" frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                                
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="widget-content">
                            <div id="fb-root"></div>                           
                            <div className="fb-page" data-href="https://www.facebook.com/pages/casansaarcom/114665778547531"
                                data-tabs="timeline" data-width="400" data-height="500" data-small-header="false"
                                data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                                <blockquote cite="https://www.facebook.com/pages/casansaarcom/114665778547531"
                                    className="fb-xfbml-parse-ignore"><a
                                        href="https://www.facebook.com/pages/casansaarcom/114665778547531">casansaar.com</a>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section className="testimn_mem padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="main_heading">
                            Testimonial <span className="h-link pull-right"><a href="">view more</a> | <a href="">Submit
                                    Details</a> </span>
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">

                          
                            <ol className="carousel-indicators">
                                <li data-target="#quote-carousel" data-slide-to="0" className="active"></li>
                                <li data-target="#quote-carousel" data-slide-to="1"></li>
                                <li data-target="#quote-carousel" data-slide-to="2"></li>
                            </ol>

                          
                            <div className="carousel-inner">
                                <div className="item active">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p><a href="">&ldquo; First of all many congratulations on creating such
                                                    a useful
                                                    website. This is Such a great website I have ever seen in my
                                                    life.
                                                    It covers almost .....&rdquo;</a></p>
                                            <small><strong>By: CA Darshan Sheth</strong></small>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p><a href="">&ldquo;First of all many congratulations on creating such
                                                    a useful
                                                    website. This is Such a great website I have ever seen in my
                                                    life.
                                                    It covers almost .....&rdquo;</a></p>
                                            <small><strong>By: CA Darshan Sheth</strong></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p><a href="">&ldquo;First of all many congratulations on creating such
                                                    a useful
                                                    website. This is Such a great website I have ever seen in my
                                                    life.
                                                    It covers almost .....&rdquo;</a></p>
                                            <small><strong>By: CA Darshan Sheth</strong></small>
                                        </div>

                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p><a href="">&ldquo;First of all many congratulations on creating such
                                                    a useful
                                                    website. This is Such a great website I have ever seen in my
                                                    life.
                                                    It covers almost .....&rdquo;</a></p>
                                            <small><strong>By: CA Darshan Sheth</strong></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p><a href="">&ldquo;First of all many congratulations on creating such
                                                    a useful
                                                    website. This is Such a great website I have ever seen in my
                                                    life.
                                                    It covers almost .....&rdquo;</a></p>
                                            <small><strong>By: CA Darshan Sheth</strong></small>
                                        </div>

                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p><a href="">&ldquo;First of all many congratulations on creating such
                                                    a useful
                                                    website. This is Such a great website I have ever seen in my
                                                    life.
                                                    It covers almost .....&rdquo;</a></p>
                                            <small><strong>By: CA Darshan Sheth</strong></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-4">
                        <div className="main_heading">
                            Our Members <span className="h-link pull-right"><a href="">view more</a> </span>
                        </div>

                        <div className="tabmar_top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#om_newest" aria-controls="om_newest"
                                        role="tab" data-toggle="tab">Newest</a></li>
                                <li role="presentation"><a href="#om_active" aria-controls="om_active" role="tab"
                                        data-toggle="tab">Active</a></li>
                                <li role="presentation"><a href="#om_popular" aria-controls="om_popular" role="tab"
                                        data-toggle="tab">Popular</a></li>

                            </ul>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane fade in active" id="om_newest">
                                    <ul className="tablatest newlist">
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />   
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="om_active">
                                    <ul className="tabpopular newlist">
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="om_popular">
                                    <ul className="tabgst newlist">
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="on_newimg">
                                                <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                            </div>
                                            <div className="on_newcont">
                                                <a href="">Pawan Kasat </a>
                                                <p><strong>19-Mar-2020, 11:12:58</strong></p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="interviews padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="inter_cont">
                            <h3 className="inte_heading">Interviews</h3>
                            <div className="interview_cont">
                                <ul id="marquee-horizontal">
                                    <li>
                                        <p><img src="assets/images/in_img.jpg" className="intev_img" alt="" />
                                            <span>INTERVIEW OF Dr.
                                                RAKESH GUPTA ( FCA, FCS,
                                                AICWA, LLB, PhD, Ex- Member, Income Tax Appellate Tribunal ) BY CA.
                                                NEHA
                                                JAIN……
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p><img src="assets/images/in_img.jpg" className="intev_img" alt="" />
                                            <span>INTERVIEW OF Dr.
                                                RAKESH GUPTA ( FCA, FCS,
                                                AICWA, LLB, PhD, Ex- Member, Income Tax Appellate Tribunal ) BY CA.
                                                NEHA
                                                JAIN……
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p><img src="assets/images/in_img.jpg" className="intev_img" alt="" />
                                            <span>INTERVIEW OF Dr.
                                                RAKESH GUPTA ( FCA, FCS,
                                                AICWA, LLB, PhD, Ex- Member, Income Tax Appellate Tribunal ) BY CA.
                                                NEHA
                                                JAIN……
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p><img src="assets/images/in_img.jpg" className="intev_img" alt="" />
                                            <span>INTERVIEW OF Dr.
                                                RAKESH GUPTA ( FCA, FCS,
                                                AICWA, LLB, PhD, Ex- Member, Income Tax Appellate Tribunal ) BY CA.
                                                NEHA
                                                JAIN……
                                            </span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="oppinpoll padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="main_heading">
                            Stars of CASANSAAR
                        </div>
                        <div className="star_casansaar cont_bord">
                            <ul className="star_header">
                                <li className="t_name">
                                    <div className="t_name"><strong>Name</strong></div>
                                    <div className="t_pic"><strong>Pic</strong></div>
                                    <div className="t_point"><strong>Points</strong></div>
                                    <div className="t_rank"><strong>Rank</strong></div>
                                </li>
                            </ul>

                            <div className="maq_cont">
                                <ul className="marquee-vertical">
                                    <li>
                                        <div className="t_name"><strong>Vinay Sharma</strong></div>
                                        <div className="t_pic"><img src="assets/images/157441563616caCwZH_400x400.jpg"
                                                alt="" className="img-responsive" /></div>
                                        <div className="t_point">74672</div>
                                        <div className="t_rank">1</div>
                                    </li>
                                    <li>
                                        <div className="t_name"><strong>Vinay Sharma</strong></div>
                                        <div className="t_pic"><img src="assets/images/157441563616caCwZH_400x400.jpg"
                                                alt="" className="img-responsive" /></div>
                                        <div className="t_point">74672</div>
                                        <div className="t_rank">1</div>
                                    </li>
                                    <li>
                                        <div className="t_name"><strong>Vinay Sharma</strong></div>
                                        <div className="t_pic"><img src="assets/images/157441563616caCwZH_400x400.jpg"
                                                alt="" className="img-responsive" /></div>
                                        <div className="t_point">74672</div>
                                        <div className="t_rank">1</div>
                                    </li>
                                    <li>
                                        <div className="t_name"><strong>Vinay Sharma</strong></div>
                                        <div className="t_pic"><img src="assets/images/157441563616caCwZH_400x400.jpg"
                                                alt="" className="img-responsive" /></div>
                                        <div className="t_point">74672</div> 
                                        <div className="t_rank">1</div>
                                    </li>
                                    <li>
                                        <div className="t_name"><strong>Vinay Sharma</strong></div>
                                        <div className="t_pic"><img src="assets/images/157441563616caCwZH_400x400.jpg"
                                                alt="" className="img-responsive" /></div>
                                        <div className="t_point">74672</div>
                                        <div className="t_rank">1</div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="main_heading">
                            Online members (30) <span className="h-link pull-right"><a href="">view more</a> </span>
                        </div>
                        <div className="onlinemember cont_bord">
                            <ul>
                                <li>
                                    <div className="on_newimg">
                                        <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                    </div>
                                    <div className="on_newcont">
                                        <p><a href="">MADHUSUDAN LADDHA <img src="assets/images/online.gif" alt="" /></a>
                                        </p>
                                        <p><small>1 Friends</small></p>
                                    </div>
                                </li>
                                <li>
                                    <div className="on_newimg">
                                        <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                    </div>
                                    <div className="on_newcont">
                                        <p><a href="">MADHUSUDAN LADDHA <img src="assets/images/online.gif" alt="" /></a>
                                        </p>
                                        <p><small>1 Friends</small></p>
                                    </div>
                                </li>
                                <li>
                                    <div className="on_newimg">
                                        <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                    </div>
                                    <div className="on_newcont">
                                        <p><a href="">MADHUSUDAN LADDHA <img src="assets/images/online.gif" alt="" /></a>
                                        </p>
                                        <p><small>1 Friends</small></p>
                                    </div>
                                </li>
                                <li>
                                    <div className="on_newimg">
                                        <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" /> 
                                    </div>
                                    <div className="on_newcont">
                                        <p><a href="">MADHUSUDAN LADDHA <img src="assets/images/online.gif" alt="" /></a>
                                        </p>
                                        <p><small>1 Friends</small></p>
                                    </div>
                                </li>
                                <li>
                                    <div className="on_newimg">
                                        <img src="assets/images/1543038832Photo -VIJAY SIR.jpg" alt="" />
                                    </div>
                                    <div className="on_newcont">
                                        <p><a href="">MADHUSUDAN LADDHA <img src="assets/images/online.gif" alt="" /></a>
                                        </p>
                                        <p><small>1 Friends</small></p>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="main_heading">
                            Opinion Poll <span className="h-link pull-right"><a href="">view more</a> </span>
                        </div>
                        <div className="opnion_con">
                            <div className="op_link">
                                <a href="opinion-poll/Are-you-satisfied-with-Union-Interim-Budget-2020--21/45.html">Are
                                    you satisfied with Union Interim Budget 2020 -21?..</a>
                            </div>
                            <div className="op_true_f">
                                <ul>
                                    <li><span>Yes</span> <input type="radio" /></li>
                                    <li><span>No</span> <input type="radio" /></li>
                                    <li><span>Can’t Say</span> <input type="radio" /></li>
                                    <li className="text-center"><input type="submit" className="btn btn-blue" value="Vote Now" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="rewardpoints padd_tb">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div style={{ height: 'auto', maxHeight: '200px', overflow: 'scroll' }}>
                            <p className="news_short">
                                Exclusive Circle For <a href="https://www.casansaar.com">CA</a>,CS,CMA,FINANCE.CA
                                SANSAAR A <strong>Top Accounting Firms</strong> Online Community for <a
                                    href="https://www.casansaar.com/news/Chartered-Accountant.html">Chartered
                                    Accountant</a>,Company Secretary,Cost Accountant.<br></br>
                                <br></br>
                                Professional News For CA,CS,CMA.Fresh Accounting News Articles On Income Tax,Service
                                Tax,Sales Tax, Vat,Company Law,<a
                                    href="https://www.casansaar.com"><strong>RBI</strong></a>,CAG,Custom,Excise On
                                CASANSAAR
                                <br></br>
                                Find Chartered Accountant Articles On Casansaar.Unique Articles By CA,CS,CMA
                                Professionals.Get Accounting Internships,Ca Articleship Status Online.<br></br>
                                <br></br>
                                CASANSAAR Platform Of Discussion For CA,CS,CMA.Discussion On <a
                                    href="https://www.casansaar.com">Income Tax</a>,Sales Tax,Service Tax
                                Excise,Custom.Online Community For Chartered Accountants<br></br>
                                <br></br>
                                CASANSAAR Is One Of India’s Leading Online <a href="https://www.casansaar.com">Ca
                                    Firms In Delhi</a>.Online Ca Community For Top Accounting Firms For Charted
                                Accountant,CS,CMA Professionals.<br></br>
                                <br></br>
                                <a href="https://www.casansaar.com"><strong>Accounting Jobs</strong></a> For
                                Chartered Accountants,Company Secretaries,CMA &amp; Semi Qualifieds.Get Finance jobs
                                for CA On Casansaar.Post,Search CA Jobs Directly.<br></br>
                                <br></br>
                                Fetch CA Firm For <em><strong>Articles Trainees</strong></em>,Trainee
                                Accountant,Article Assistant,Paid Assistants Only On CASANSAAR.Online <a
                                    href="https://www.casansaar.com">Ca Community</a> For Cs,Cma,interners.<br></br>
                                <br></br>
                                Try Best Website Creator For Professionals CA,CS,CMA On CASANSAAR.Top Php Websites
                                Creater For <strong>Chartered Accountant</strong> For CA Professional Website
                                Online<br></br>
                                <br></br>
                                <a href="https://www.casansaar.com">Empanelment </a>Of Valuers On
                                Casansaar.Empanelment Of Chartered Accountants For<a
                                    href="https://www.casansaar.com"><strong> Concurrent Audits</strong></a>,Bank
                                Audits,Cag Audits.Top Empanelled News,Articles.<br></br>
                                <br></br>
                                File Server On Casansaar to Upload-Downlaod Professional pdf Files by CA,CS,CMA.By
                                File manager,download ITR,TDS Chart<strong>,income tax slab</strong>,ServiceTax
                                etc.<br></br>
                                <br></br>
                                Exclusive<strong> Matrimonials Site For CA,CS,CMA Brides Grooms</strong> In
                                India.Finding CA/CS/CMA As Life Partner,Visit Casansaar.FREE Profile on Community
                                Matrimony<br></br>
                                <br></br>
                                All Queries For IncomeTax,Auditing,Gst,<strong>ITR,<a
                                        href="https://www.casansaar.com">TDS</a></strong> Chart on
                                Casansaar.CACommunity for all CA firms in delhi.Cpt Queries,accounting
                                jobs,Judiciary,matrimony<br></br>
                                <br></br>
                                Get Partners,Networking,Merger,Assignments For CA,CS,CMA On
                                <em>CASANSAAR</em>.India’s Online Free Ads Posting classNameified,CA Advertising
                                classNameified Ads Website<br></br>
                                <br></br>
                                Come And Join Our Community For <strong>CA,CS,CMA</strong> on CASANSAAR.India’s Best
                                <em><strong>CA Community</strong></em> To Find Accounting Jobs,Article Trainees,Ca
                                Matrimony,CoachingclassNamees.<br></br>
                                <br></br>
                                Events for Chartered Accountant,<a href="https://www.casansaar.com">Company
                                    Secretaries</a>,Cost Accountants.All CA Firms in Delhi,Top Accounting Firms
                                under CASANSAAR,Shares event Online.&nbsp;<br></br>
                                <br></br>
                                Be <strong>Top Members</strong> of CASANSAAR,Circle For Ca,CS,CMA.All Ca Firms In
                                Delhi,<strong>Top Accounting Firms,</strong>Participating to be part of top members
                                by posting,Sharing<br></br>
                                <br></br>
                                CASANSAAR provides coaching classNamees for CA,CS,CMA.India Certified CA Coaching In
                                Delhi.Top <strong>Coaching Centre </strong>For CA.Service Provider of coaching
                                classNamees<br></br>
                                <br></br>
                                Find Testimonials Of Ca,Cs,Cma,All Reputed Professional <a
                                    href="https://www.casansaar.com">Ca Networking Firms</a> On Our Partner Portal
                                On Casansaar,Including Jobs,<strong>Coaching classNamees</strong>,Budget<br></br>
                                <br></br>
                                Get Judiciary Website For Judicial Search,Law,Court System On CASANSAAR Includes
                                <em>Judiciary</em> Law,Case Laws Of Supreme Court,High Courts,ITAT,CBEC,CBDT.<br></br>
                                <br></br>
                                Find <a href="https://www.casansaar.com">CA </a>Announcement,Notification,Circulars
                                by Income Tax Department<strong>,VAT</strong>,<strong><em>Service
                                        Tax</em></strong>,Excise,Custom Department on CASANSAAR.India&nbsp; Top CA
                                Notification.<br></br>
                                <br></br>
                                Grab Top CA Books Online On Casansaar.Range Of Chartered Accountant Books For Ca
                                Firms In Delhi,<strong>Student Zone</strong>,CS,CMA,Accounting firms,Article
                                Trainees.<br></br>
                                <br></br>
                                Casansaar&nbsp; Offers Ca Edirectory For CA,CS,CMA &amp; Accounting Jobs<strong>,<a
                                        href="https://www.casansaar.com">Article Trainees</a></strong>,Students
                                Zone,Coaching classNamees,classNameified Ads,Matrimonial,Edirectory.<br></br>
                                <br></br>
                                At Casansaar Win Gifts Score On Scoreboard,By Posting,Searching News For
                                <strong>CA,CS,CMA</strong> On Income Tax,Empanelment,<a
                                    href="https://www.casansaar.com">ITR</a>,TDS
                                Chart,Gst,Auditing,Cpt,Judiciary.<br></br>
                                <br></br>
                                Press Release On Ca Sansaar On Professional News For CA,CS,CMA.News Or Updates&nbsp;
                                on Income Tax<strong>,ITR,TDS</strong> Chart,Gst,<a
                                    href="https://www.casansaar.com"><strong>Auditing</strong></a>,Empanelment,Cpt,Judiciary,CA.<br></br>
                                <br></br>
                                On Casansaar,Online Ca Community With All Ca Members Profile Being Active Part Of
                                Variety Of Topics Judiciary,Auditing,<strong>Gst</strong>,Income Tax Etc.Win
                                Gifts.<br></br>
                                <br></br>
                                Ca Students On CASANSAAR,Top <a href="https://www.casansaar.com">Students
                                    Zone</a>,Journal,Portal,Club For Chartered Accountant Students to Increase
                                Exposure of <strong>Ca Students</strong> in industrial job<br></br>
                                <br></br>
                                <strong><a href="https://www.casansaar.com">ITR Forms</a>, Companies
                                    Act,&nbsp;</strong><a
                                    href="https://www.casansaar.com/news/Finance-Bill.html">Finance Bill</a> Gets
                                President's Assent<br></br>
                                <span
                                    style={{ 
                                        height: 'auto', 
                                        maxHeight: '200px', 
                                        overflow: 'scroll', 
                                        backgroundColor: 'rgb(255, 255, 204)', 
                                        color: 'rgb(0, 0, 0)', 
                                        fontFamily: 'arial, helvetica, sans-serif', 
                                        fontSize: '12px' 
                                      }}><a
                                        href="https://www.casansaar.com/news/Service-Tax.html">Service Tax Rate
                                    </a>of 14% will be effective from 01st June 2015</span><br></br>
                                <strong><span style={{fontSize: '9x' }}><a href="https://www.casansaar.com">JOBS FOR
                                            CA</a>|CA
                                        JOBS|JOB|CA-RECRUITMENT|EMPLOYMENT|VACANCIES|CASANSAAR</span></strong><br></br>
                                <span style={{fontSize: '12px' }}><span style={{ fontFamily: 'arial, helvetica, sans-serif'}}>Get
                                        chartered accountant
                                        jobs,ca jobs,recruitment,employment,<a
                                            href="https://www.casansaar.com">vacancies for ca </a>on casansaar.job
                                        search for career,job opportunities for ca on job sites</span></span><br></br>
                                &nbsp; </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 text-center">
                        <img src="assets/images/rewards_points.jpg" alt="" className="reward_img" />
                    </div>

                </div>
            </div>
        </section>
        </main>

   </>
  )
}

export default Home
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate , Link } from 'react-router-dom';
import Pagination from '../common/Pagination.js';
import axios from 'axios';
import RightContent from './RightContent';

function NewsBrowse() {
  const { cat_name, page } = useParams();
  const [responseData, setResponse] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(page);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  
  const maxLength = 200;
  const truncateText = (text, length) => {
    return text.length > length ? text.substr(0, length) + '...' : text;
  };
  

  useEffect(() => {

    console.log('cat_name_'+cat_name+'');
    console.log('page_'+page+'');
    /*let url = '';
    if (cat_name && page) {
      url = `https://api.casansaar.co/api/news?cat_name=${cat_name}&page=${page}`;
    } else if (cat_name) {
      url = `https://api.casansaar.co/api/news?cat_name=${cat_name}`;
    } else if (page) {
      url = `https://api.casansaar.co/api/news?page=${page}`;
    } else {
      url = `https://api.casansaar.co/api/news`;
    }
*/
let url = `https://api.casansaar.co/api/news?page=${currentPage}`;
if (cat_name) {
  url += `&cat_name=${cat_name}`;
}
if (searchQuery) {
  url += `&search=${searchQuery}`;  // Add the search query to the URL
}

    axios.get(url)
      .then(response => {
        setResponse(response.data.data);
        setCurrentPage(response.data.data.current_page);
        setTotalPages(response.data.data.last_page);
        
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [cat_name, currentPage, searchQuery]);


   // Start: Pagination
   const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Closed: Pagination


  //Start: Search fun
  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1); // Reset to the first page when performing a new search
  };


  //Close: Search 

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
   <>

<main class="articles_page">
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="articles-list">
                            <div class="main_heading">
                                News - <span class="h-link"> (Get Professional Updates on Whatsapp, Msg on
                                    8285393786)</span>
                            </div>

                            <ul class="ullist cont_bord">
                            {responseData.data.length > 0 ? (
                              responseData.data.map(newsItem => (
                                <li key={newsItem.news_id}>
                                    <div class="list-cont">
                                        <div class="newsheading">   <Link to={`/${newsItem.full_url}`}>{newsItem.news_title}</Link>                             </div>
                                        <div class="post_dett">
                                        <span>Posted Date : {new Date(newsItem.posted_date).toLocaleDateString()} | Posted By {newsItem.posted_by}</span>
                                         <span class="artimg"></span>
                                        </div>
                                        <p className="newsshort"> {truncateText(newsItem.news_matter.replace(/<[^>]+>/g, ''), maxLength)}</p>
                                        <span className="categoryshort">Category :
                                          <Link to="">Bank Branch Audit</Link> | Comments : 0 | Hits : {newsItem.hits}
                                        </span>
                                        <p className="canews">
                                          {(truncateText(newsItem.news_matter.replace(/<[^>]+>/g, ''), maxLength).length > maxLength) && <Link to={`/${newsItem.full_url}`}>Continue Reading...</Link>}
                                        </p>
                                        <hr></hr>
                                    </div>
                                </li>
                                ))
                              ) : (
                                <p>No news articles found.</p>
                              )} 
                            </ul>
                        </div>

                       
{/* <nav class="navigation pagination" role="navigation" aria-label=" ">
                            <h2 class="screen-reader-text"> </h2>
                            <div class="nav-links"><a class="prev page-numbers" href=""><span
                                        class="screen-reader-text">Previous</span></a>
                                <a class="page-numbers" href=""><span class="meta-nav screen-reader-text"> </span>1</a>
                                <span aria-current="page" class="page-numbers current"><span
                                        class="meta-nav screen-reader-text"> </span>2</span>
                                <a class="page-numbers" href=""><span class="meta-nav screen-reader-text"> </span>3</a>
                                <a class="page-numbers" href=""><span class="meta-nav screen-reader-text"> </span>4</a>
                                <a class="next page-numbers" href=""><span class="screen-reader-text">Next</span></a>
                            </div>
                        </nav>


                        <nav className="navigation pagination" role="navigation" aria-label="Pagination Navigation">
              
                  <div className="nav-links">
                    <button
                      className="prev page-numbers"
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages).keys()].map(num => (
                      <button
                        key={num + 1}
                        className={`page-numbers ${currentPage === num + 1 ? 'current' : ''}`}
                        onClick={() => handlePageChange(num + 1)}
                      >
                        {num + 1}
                      </button>
                    ))}
                    <button
                      className="next page-numbers"
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                </nav>
*/}



<nav className="navigation pagination" role="navigation" aria-label="Pagination Navigation">
              
                    <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    />
                     </nav>   
                    </div>
                    
                    <RightContent  onSearch={handleSearch} searchQuery={searchQuery} ></RightContent>


                </div>
            </div>


        </section>
    </main>

   
   </>
  );
}

export default NewsBrowse;

import React , {useState} from "react";
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; //do not remove this

const Footers = () => {
   

    return (
        <> 
        <footer id="footer">
        <div className="container qqqlink">
            <div className="row">
                <div className="col-xs-12">
                    <div className="">
                        <h3> www.casansaar.com ... keeps you updated </h3>
                    </div>
                    <div className="qi_link">
                        <a href="">Home</a> | <a href="">News</a> | <a href="">Articles</a> | <a href="">Discussion</a>
                        | <a href="">CA Firms</a> | <a href="">Members</a> | <a href="">CA Community</a> | <a
                            href="">Jobs</a> | <a href="">Empanelment
                        </a> | <a href="">Files</a> | <a href="">Matrimonial</a> | <a href="">Students zone</a> | <a
                            href="">Queries</a> | <a href="">classNameified Ads</a> | <a href="">Events</a> | <a
                            href="">About us</a> | <a href="">Top
                            Members</a> | <a href="">Coaching Centres</a> | <a href="">Testimonials</a> | <a
                            href="">Judiciary</a> | <a href="">Videos</a> | <a href="">Notifications/Circulars</a> |
                        <a href="">Book</a> | <a href="">eDirectory</a> | <a href="">Interview</a> | <a
                            href="">Website</a> | <a href="">Emails</a> | <a href="">Software</a> | <a href="">Score
                            Board</a> | <a href="">Press Release</a> | <a href="">Blood Bank</a> | <a href="">Opinion
                            Poll</a> | <a href="">Feedback</a> | <a href="">Advertise us</a> | <a href="">Terms &
                            Conditions</a> | <a href="">Contact us</a> | <a href="">Blog</a> | <a href="">Sitemap</a> |
                        <a href="">Income Tax</a> | <a href="">Auditing</a> | <a href="">Bank</a> | <a href="">Bank
                            branch audit</a> | <a href="">Banking</a> | <a href="">Black Money</a> | <a href="">CA</a> |
                        <a href="">CA Announcement</a> | <a href="">TDS</a> | <a href="">Students Zone</a> | <a
                            href="">Custom</a> | <a href="">VAT</a> | <a href="">Service Tax</a> | <a href="">Corporate
                            Law</a> | <a href="">General</a> | <a href="">Excise</a> | <a href="">SEBI</a> | <a
                            href="">Finance</a> | <a href="">GST</a> | <a href="">ICAI</a> | <a href="">FEMA</a> |
                        <a href="">ICWAI</a> | <a href="">ICSI</a> | <a href="">DGFT</a> | <a href="">RBI</a> | <a
                            href="">IFRS</a> | <a href="">CAG</a> | <a href="">Budget</a> | <a href="">Merger &
                            Acquisitions</a> | <a href="">International Business</a> | <a href="">Shares & Stock</a>
                        | <a href="">Excel Tips</a> | <a href="">International Taxation</a> | <a href="">ITR</a> |
                        <a href="">Chartered Accountant</a> | <a href="">Tax Audit</a> | <a href="">Companies
                            Act</a> | <a href="">Money Laundering</a> | <a href="">MCA</a> | <a href="">Income Tax
                            Refund</a> | <a href="">NPA</a> | <a href="">Income tax software</a> | <a href="">CST</a> |
                        <a href="">CA Exams</a> | <a href="">CA Results</a> | <a href="">CBDT</a> | <a
                            href="">Accounting Standards</a> | <a href="">DIN</a> | <a href="">Judical Announcement</a>
                        | <a href="">MDP</a> | <a href="">PAN</a> | <a href="">Whats App</a> | <a href="">Secretarial
                            Standards</a> | <a href="">NGO</a> | <a href="">CA Convocation</a> | <a href="">Finance
                            Bill</a> | <a href="">ICAI
                            Notification</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer_bottom">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 social_icon">
                        <div className="flowtext">Follow Us</div>
                        <div className="flow_icon">
                            <a href=""><img src="assets/images/social-media/facebook_ico_footer.svg" /></a>
                            <a href=""><img src="assets/images/social-media/instagram_ico_footer.svg" /></a>
                            <a href=""><img src="assets/images/social-media/link_ico_footer.svg" /></a>
                            <a href=""><img src="assets/images/social-media/youtube_ico_footer.svg" /></a>
                        </div>
                        <div className="conpy_rig">
                            Copyright © casansaar <a href="">About Us</a> <a href="">Advertise</a> - <a href="">Terms &
                                Conditions</a>
                        </div>
                    </div>
                    <div className="col-sm-6 hidden-xs fotright  text-right">
                        <a target="_blank" href="https://feeds.feedburner.com/casansaar.blogspot.com?fmt=xml"
                            rel="nofollow"><img src="assets/images/CASANSAAR.12.gif"
                                alt="CASANSAAR Updates" className="img-responsive" /></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>

        
        
        
        </>
    )
}
export default Footers;

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/style.min.css';

import { Tabs, Tab } from 'react-bootstrap';

import  Headers from './component/common/Header.js';
import  Footers from './component/common/Footer.js';
import Home from './component/Home.js';
import NewsBrowse from './component/News/NewsBrowse.js';
import NewsCategory from './component/News/NewsCategory.js';
import NewsDetails from './component/News/NewsDetails.js';
import NoPage from './component/common/NoPage.js';

function App() {
  return (
    
    <div className="App">
    <Headers></Headers>
      <Routes>
        {/* <Route path='/' element={<Home></Home>} />
        <Route path='/news' element={<News></News>} />
        <Route path="/news/:newsId" element={<NewsDetails></NewsDetails>} />
        <Route path='/newscat' element={<NewsCategory></NewsCategory>} />

      */}
      <Route path='/' element={<Home></Home>} />

       <Route path="/news/:cat/:title/:id.html" element={<NewsDetails />} />
  
       <Route path="/news.html" element={<NewsBrowse />} />

       
        <Route path="/news/:cat_name/page/:page.html" element={<NewsBrowse />} />
      
        <Route path="/news/:cat_name.html" element={<NewsBrowse />} />
        <Route path="/news-category.html" element={<NewsCategory />} />
        <Route path="/news/page/:page.html" element={<NewsBrowse />} />

        <Route path="*" element={<NoPage />} />
      
      </Routes>
      <Footers></Footers>

    </div>
        
    );
}

export default App;
